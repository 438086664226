import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation, Pagination, Autoplay } from "swiper/modules"

import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"

export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    console.log("Hello slider")
    this.swiper = new Swiper(`.${this.selectorValue}`, {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 1,
      loop: true,
      spaceBetween: 0,
      autoHeight: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    })
  }
}