import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { tagOutline, xCircleSolid } from '../../utilities/heroicons'
import htmlToElement from '../../utilities/html_to_element'

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    $("#productsSearchModal").on("shown.bs.modal", () => {
      $("#products-search").trigger("focus")
    })

    new autoComplete({
      selector: "#products-search",
      wrapper: false,
      debounce: 200,
      submit: true,
      data: {
        src: async (phrase) => {
          try {
            let url = `${this.urlValue}?phrase=${phrase}`
            const source = await fetch(url)
            const data = await source.json()

            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-p-4 tw-py-8 tw-justify-center tw-flex tw-items-center">
                ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                <span>
                  Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                </span>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: (item, data) => {
          let image_picture_element = `
            <div class="tw-h-auto tw-w-[80px] lg:tw-w-[100px]">
              <img src="${data.value.picture_url}" class="tw-w-full tw-rounded"/>
            </div>
          `
          let categoryIcon = `
            <div class="tw-h-[80px] lg:tw-h-[100px] tw-w-[80px] lg:tw-w-[100px]">
              <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
                ${tagOutline("tw-h-12 tw-w-12")}
              </div>
            </div>
          `

          let image = data.value.type == "Kategori" ?  categoryIcon : image_picture_element

          item.innerHTML = `
            <div class="tw-flex tw-items-start">
              <div class="tw-mr-4">
                ${image}
              </div>
              <div class="tw-space-y-1 lg:tw-space-y-2">
                <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
                <div class="">${data.value.type}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            const url = event.detail.selection.value.path

            $("#productsSearchModal").modal("hide")
            Turbolinks.visit(url)
          }
        }
      }
    })
  }
}
