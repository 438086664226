import "jquery"
import 'bootstrap/dist/js/bootstrap'
import Turbolinks from 'turbolinks'
import datepickerFactory from 'jquery-datepicker'
import 'main'
import "pickadate/lib/picker"
import "pickadate/lib/picker.date"
import "../components"

import "@tabler/icons-webfont/tabler-icons.css"
import "pickadate/lib/themes/default.css"
import "pickadate/lib/themes/default.date.css"

datepickerFactory($)

require("@rails/ujs").start()
Turbolinks.start()

import "catalog/controllers"
