import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['navbar', 'logo', 'menuToggle', 'mobileMenu']

  connect() {
    let thisController = this

    $(window).scroll(function() {
      if ($(this).scrollTop() > 40) {
        thisController.navbarTarget.classList.replace('md:tw-p-3', 'md:tw-p-2')
      } else {
        thisController.navbarTarget.classList.replace('md:tw-p-2', 'md:tw-p-3')
      }
    })
  }

  toggleMenu(event) {
    event.preventDefault()

    this.mobileMenuTarget.classList.toggle('tw-block')
    this.mobileMenuTarget.classList.toggle('tw-hidden')

    $("#website-mobile-menu .openIcon").toggleClass('tw-hidden');
    $("#website-mobile-menu .closeIcon").toggleClass('tw-hidden');
  }
}
