import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { xCircleSolid } from "../../../javascript/utilities/heroicons"
import htmlToElement from "../../../javascript/utilities/html_to_element"

export default class extends Controller {
  static targets = ["list", "searchInput"]
  static values = {
    excludedIds: Array,
    collectionBlockUrl: String,
    collectionsAutocompletePath: String
  }

  connect() {
    const _this = this

    $(this.element).on("shown.bs.modal", () => {
      $(_this.searchInputTarget).trigger("focus")
    })

    $(this.element).on("hide.bs.modal", () => {
      $(_this.searchInputTarget).val("")
      this.collectionSearch.close()
    })

    this.collectionAutocomplete()
  }

  selectCollection({ params: {id} }) {
    this.addCollectionToBlock(id)
  }

  addCollectionToBlock(collectionId) {
    $.ajax({
      method: 'POST',
      url: this.collectionBlockUrlValue,
      data: {
        websites_blocks_collection: { collection_id: collectionId }
      },
      success: function() {
        $("#collections-autocomplete").blur()
      }
    })
  }

  collectionAutocomplete() {
    this.collectionSearch = new autoComplete({
      placeHolder: "Cari nama koleksi",
      selector: "#collection-search",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (phrase) => {
          try {
            const excludedIdsString = this.excludedIdsValue.map(element => `&exclude_ids[]=${element}`)
            const url = `${this.collectionsAutocompletePathValue}?&phrase=${phrase}&no_items=true&format=json&renting_only=true${excludedIdsString}`
            const source = await fetch(url)
            const data = await source.json()
  
            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["title"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-p-4 tw-py-8 tw-justify-center tw-flex tw-items-center">
                ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                <span>
                  Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                </span>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: (item, data) => {
          item.innerHTML = `
            <div class="tw-flex tw-items-center">
              <div class="tw-mr-4">
                <div class="tw-h-auto tw-w-[60px] lg:tw-w-[80px]">
                  <img src="${data.value.picture_url}" class="tw-w-full tw-rounded"/>
                </div>
              </div>
              <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
            </div>
          `
        }
      },
      events: {
        input: {
          keydown: () => {
            this.listTarget.classList.add("tw-hidden")
          },
          blur: () => {
            this.listTarget.classList.remove("tw-hidden")
          },
          selection: (event) => {
            let selection = event.detail.selection.value
            const collectionId = selection.id

            this.addCollectionToBlock(collectionId)
          }
        }
      }
    })
  }
}
