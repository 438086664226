import { Controller } from "@hotwired/stimulus"
import * as dateUtils from '../../packs/date_utils'
import * as strftime from 'strftime';

export default class extends Controller {
  static targets = ["startDate", "endDate", "durationDay", "pricingId"]
  static values = {
    includeStartDateOnDuration: { type: Boolean, default: false },
    onDurationMode: Boolean
  }

  connect() {
    if(this.hasPricingIdTarget) {
      $(this.durationDayTarget).on("change", () => {
        const selectedIndex = this.durationDayTarget.selectedIndex
        this.pricingIdTarget.value = this.durationDayTarget.options[selectedIndex].getAttribute("data-pricing-id")
      })
    }

    if (this.onDurationModeValue) {
      $(this.startDateTarget).on("change", () => this.changeEndDate())
    } else {
      if (this.startDateTarget.value != "" && this.endDateTarget.value != "") {
        this.writeDuration()
      }

      $(this.startDateTarget).on("change", () => {
        dateUtils.adjustStartLimit($(this.startDateTarget), $(this.endDateTarget), 0);

        if(this.endDateTarget.value != "") {
          this.writeDuration()
        }
      })

      $(this.endDateTarget).on("change", () => {
        dateUtils.adjustFinishLimit($(this.startDateTarget), $(this.endDateTarget), 0);

        if(this.startDateTarget.value != "") {
          this.writeDuration()
        }
      })
    }
  }

  changeEndDate() {
    if (this.startDateTarget.value !== "" && this.durationDayTarget.value !== "") {
      const finishCandidate = dateUtils.dateFromStringShort(this.startDateTarget.value)

      if (this.includeStartDateOnDurationValue) {
        finishCandidate.setDate((finishCandidate.getDate() - 1) + parseInt(this.durationDayTarget.value,10));
      } else {
        finishCandidate.setDate(finishCandidate.getDate() + parseInt(this.durationDayTarget.value,10));
      }

      this.endDateTarget.value = strftime("%e-%b-%Y", finishCandidate)
      this.endDateTarget.classList.remove("d-none")
    }
  }

  writeDuration() {
    const startDate = dateUtils.dateFromStringShort(this.startDateTarget.value)
    const endDate = dateUtils.dateFromStringShort(this.endDateTarget.value)

    if (startDate && endDate) {
      const period = dateUtils.daysBetween(startDate, endDate)
      this.durationDayTarget.value = `${this.includeStartDateOnDurationValue ? (period + 1) : period} hari`
    }
  }

  // Ensure quantity value filled with value 1 if empty
  fixedQuantityValue(event) {
    const value = parseInt(event.currentTarget.value)
    event.currentTarget.value = isNaN(value) || value === 0 ? 1 : value
  }

  updatePricingOptions(event) {
    const currentValue = parseInt(event.currentTarget.value)
    const quantity = isNaN(currentValue) || currentValue === 0 ? 1 : currentValue
    const currentSelectedIndex = this.durationDayTarget.selectedIndex
    const newOptions = [...this.durationDayTarget.options].map((option, index) => {
      const id = option.dataset.pricingId
      const durationDay = option.dataset.durationDay
      const price = parseInt(option.dataset.pricingPrice)
      const onDiscount = option.dataset.onDiscount
      const formattedPrice = (price * quantity).toLocaleString("id-ID")
      const optionElement = document.createElement("option")

      optionElement.value = durationDay
      optionElement.text = `${durationDay} hari - Rp${formattedPrice} ${onDiscount}`
      optionElement.setAttribute("data-duration-day", durationDay)
      optionElement.setAttribute("data-pricing-id", id)
      optionElement.setAttribute("data-pricing-price", price)
      optionElement.setAttribute("data-on-discount", onDiscount)

      if (index === currentSelectedIndex) {
        optionElement.setAttribute("selected", "selected")
      }

      return optionElement
    })

    // Remove old select options
    Array.from(this.durationDayTarget.options).forEach(option => option.remove())

    // Add new select options
    newOptions.forEach(option => {
      this.durationDayTarget.add(option)
    })
  }
}
