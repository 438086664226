
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('.nav-tabs > li a[title]').tooltip();

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var target = $(e.target);

      if (target.parent().hasClass('disabled')) {
        return false;
      }
    });

    $(".next-step").on('click', function (e) {
      var active = $('.wizard .nav-tabs li.active');
      active.next().removeClass('disabled');
      nextTab(active);
    });

    $(".prev-step").on('click', function (e) {
      var active = $('.wizard .nav-tabs li.active');
      prevTab(active);
    });

    function nextTab(elem) {
      $(elem).next().find('a[data-toggle="tab"]').trigger('click');
    }

    function prevTab(elem) {
      $(elem).prev().find('a[data-toggle="tab"]').trigger('click');
    }

    $('.nav-tabs').on('click', 'li', function() {
      $('.nav-tabs li.active').removeClass('active');
      $(this).addClass('active');
    });

  }
}
