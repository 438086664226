import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mobileMenu"]
  connect() {
    console.log("Hello, Stimulus!", this.element);
  }

  toggleMenu(event) {
    event.preventDefault()

    this.mobileMenuTarget.classList.toggle("tw-flex")
    this.mobileMenuTarget.classList.toggle("tw-hidden")

    $("#website-mobile-menu .openIcon").toggleClass("tw-hidden");
    $("#website-mobile-menu .closeIcon").toggleClass("tw-hidden");
  }
}
