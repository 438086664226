import { Controller } from "@hotwired/stimulus"
import Core from "@uppy/core"
import FileInput from "@uppy/file-input"
import AwsS3 from "@uppy/aws-s3"
import ThumbnailGenerator from "@uppy/thumbnail-generator"
import DropTarget from "@uppy/drop-target"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "uploadBtn", "dragAndDropArea", "helpText", "thumbnailContainer",
    "thumbnailTemplate", "pictureFormTemplate", "pictureForm"
  ]

  static values = {
    allowedFileTypes: Array,
    maxFiles: { type: Number, default: 1 },
    context: String,
    rentalId: String,
    model: String
  }

  connect() {
    this.uppy = new Core({
      autoProceed: true,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes: this.allowedFileTypesValue,
        maxNumberOfFiles: this.maxFilesValue
      }
    })
    .use(FileInput, { target: this.uploadBtnTarget, pretty: true })
    .use(DropTarget, { target: this.dragAndDropAreaTarget })
    .use(ThumbnailGenerator, { thumbnailHeight: 300, waitForThumbnailsBeforeUpload: true })
    .use(AwsS3, { companionUrl: '/' })

    this.uppy.on("thumbnail:generated", (file, preview) => {
      this.helpTextTarget.classList.add("tw-hidden")
      this.uploadBtnTarget.classList.add("tw-hidden")
      
      const thumbnailTemplate = this.thumbnailTemplateTarget.content.cloneNode(true)
      const thumbnailId = this.cleanUpFileId(file.id)
      
      const thumbnailWrapper =  thumbnailTemplate.querySelector(".thumbnail-wrapper")
      thumbnailWrapper.setAttribute("id", thumbnailId)
      thumbnailTemplate.querySelector(".image").src = preview
      
      this.thumbnailContainerTarget.appendChild(thumbnailTemplate)
      this.thumbnailContainerTarget.classList.remove("tw-hidden")
    })

    this.uppy.on("upload-success", (file, response) => {
      const uploadedFileData = {
          id: file.meta["key"].match(/^cache\/(.+)/)[1],
          storage: "cache",
          metadata: {
            size: file.size,
            filename: file.name,
            mime_type: file.type
          }
      }
      const placeholder = (new Date).getTime()
      const thumbnailId = this.cleanUpFileId(file.id)
      const thumbnailWrapper = this.thumbnailContainerTarget.querySelector(`#${thumbnailId}`)

      thumbnailWrapper.querySelector(".loading-overlay").classList.add("tw-hidden")
      thumbnailWrapper.querySelector(".check-mark").classList.remove("tw-hidden")

      const pictureFormTemplate = this.pictureFormTemplateTarget.content.cloneNode(true)
      const rentalField = pictureFormTemplate.querySelector(".rental")
      const imageField = pictureFormTemplate.querySelector(".image")
      const contextField = pictureFormTemplate.querySelector(".context")
      const fileNameField = pictureFormTemplate.querySelector(".file-name")

      rentalField.setAttribute("name", rentalField.getAttribute("name").replace(/PLACEHOLDER/, placeholder))
      rentalField.setAttribute("id", rentalField.getAttribute("id").replace(/PLACEHOLDER/, placeholder))
      rentalField.value = this.rentalIdValue

      imageField.setAttribute("name", imageField.getAttribute("name").replace(/PLACEHOLDER/, placeholder))
      imageField.setAttribute("id", imageField.getAttribute("id").replace(/PLACEHOLDER/, placeholder))
      imageField.value = JSON.stringify(uploadedFileData)

      contextField.setAttribute("name", contextField.getAttribute("name").replace(/PLACEHOLDER/, placeholder))
      contextField.setAttribute("id", contextField.getAttribute("id").replace(/PLACEHOLDER/, placeholder))
      contextField.value = this.contextValue

      fileNameField.setAttribute("name", fileNameField.getAttribute("name").replace(/PLACEHOLDER/, placeholder))
      fileNameField.setAttribute("id", fileNameField.getAttribute("id").replace(/PLACEHOLDER/, placeholder))
      fileNameField.value = file.name

      this.pictureFormTarget.appendChild(imageField)
      this.pictureFormTarget.appendChild(contextField)
      this.pictureFormTarget.appendChild(fileNameField)
      this.pictureFormTarget.appendChild(rentalField)
    })

    this.uppy.on("complete", (_result) => {
      Rails.fire(this.element, "submit");
    })
  }

  cleanUpFileId(fileId) {
    return fileId.replace(/(\/|\-)/g, "_")
  }
}
