import { Controller } from "@hotwired/stimulus"
import { exclamationCircleSolid } from "../../utilities/heroicons"

export default class extends Controller {
  static targets = [
    "errorMessage", "modalTitle", "startDate", "endDate", "quantity",
    "pricingId", "durationDay", "productId", "colorId", "variantId",
    "sizeId"
  ]

  handleSubmit(e) {
    e.preventDefault()

    const form = this.element
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value
    const pricingId = this.pricingIdTarget.value
    const durationDay = this.durationDayTarget.value
    const productId = this.productIdTarget.value
    const variantId = this.variantIdTarget.value
    const colorId = this.colorIdTarget.value
    const sizeId = this.sizeIdTarget.value
    const quantity = this.quantityTarget.value
    const authenticityToken = $(form).serializeArray()[0]["value"]
    const formData = {
      authenticity_token: authenticityToken,
      cart_item_group: {
        start_at: startDate,
        end_at: endDate,
        pricing_id: pricingId,
        product_id: productId,
        quantity: parseInt(quantity),
        variant_id: variantId,
        color_id: colorId,
        size_id: sizeId
      }
    }

    if (startDate && endDate && durationDay && pricingId) {
      this.addItemToCart(form, formData)
    } else {
      modalTitleElement.innerHTML = this.defaultModaltitle()
      errorMessageElement.innerHTML = this.errorMessage()
      $("#flashModal").modal("show")
    }
  }

  addItemToCart(form, formData) {
    $.ajax({
      method: form.method,
      url: form.action,
      data: formData,
      dataType: "json",
      success: function() {
        $("#cartSuccessModal").modal("show")
      },
      error: (error) => {
        const messages = error.responseJSON.messages

        if (messages == "item_not_found") {
          this.modalTitleTarget.innerHTML = this.itemNotFoundModalTitle()
          this.errorMessageTarget.innerHTML = this.itemNotFoundMessage()
        } else {
          this.modalTitleTarget.innerHTML = this.defaultModaltitle()
          this.errorMessageTarget.innerHTML = messages
        }

        $("#flashModal").modal("show")
      }
    })
  }

  errorMessage() {
    return `
      Mohon periksa kembali.
      <span class="tw-font-semibold tw-text-gray-700">Tanggal Mulai, Tanggal Selesai dan Lama sewa</span>
      tidak boleh kosong
    `
  }

  itemNotFoundMessage() {
    return `
      Produk ini tidak tersedia pada tanggal yang dipiih. Silahkan coba tanggal atau produk lain, atau
      langsung hubungi kami untuk rekomendasi pengganti.
    `
  }

  defaultModaltitle() {
    return `
      <div class="tw-flex tw-items-center">
        <div class="tw-w-5 tw-mr-2">${exclamationCircleSolid("tw-h-5 tw-w-5 tw-text-red-600")}</div>
        <span>Perhatian</span>
      </div>
    `
  }

  itemNotFoundModalTitle() {
    return `<span class="tw-font-semibold tw-text-gray-700">🙏 Maaf</span>`
  }
}
