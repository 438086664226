import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    clear: Boolean,
    holidaysUrl: String,
    disabledDates: {type: Array, default: []},
    minDate: String,
    maxDate: String
  }

  connect() {
    const _this = this

    const options = {
      container: ".pickadate-container",
      firstDay: 1,
      weekdaysShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
      today: "Hari ini",
      close: "Tutup",
      clear: false,
      formatSubmit: '',
      format: "d-mmm-yyyy",
      disable: this.disabledDatesValue,
      min: this.minDateValue,
      onRender: function(){
        const pickerTable = this.$root.find(".picker__table")
        const holidaysContainerTemplate = `
          <div class="holidays-container tw-border-t tw-border-gray-300 tw-mb-4 tw-h-32 lg:tw-h-48 tw-p-4 tw-overflow-scroll"></div>
        `

        pickerTable.after(holidaysContainerTemplate)

        if (_this.hasHolidaysUrlValue) {
          const date = this.get("view", "d-mmm-yyyy")
          const urlHtml = `${_this.holidaysUrlValue}?date=${date}`
          const urlJson = `${_this.holidaysUrlValue}.json?date=${date}`

          fetch(urlHtml, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            credentials: "include"
          })
            .then(res => res.text())
            .then(data => {
              const holidaysContainer = this.$root.find(".holidays-container")[0]
              holidaysContainer.innerHTML = ""
              holidaysContainer.innerHTML = data
            })

          fetch(urlJson, {
            headers: {"X-Requested-With": "XMLHttpRequest"},
            credentials: "include"
          })
            .then(res => res.json())
            .then(data => _this.disabledDatesValue = data["holidays"])
        }
      }
    }

    if (this.hasClearValue) {
      options.clear = "Hapus"
    }

    if (this.hasMinDateValue) {
      options.min = this.minDateValue
    }

    if (this.hasMaxDateValue) {
      options.max = this.maxDateValue
    }

    this.picker = $(this.element).pickadate(options)
  }

  disabledDatesValueChanged(value) {
    if(this.picker) {
      this.picker.pickadate("picker").set("disable", value)
    }
  }
}
