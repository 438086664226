import { Controller } from "@hotwired/stimulus";
import autoComplete from "@tarekraafat/autocomplete.js"
import htmlToElement from "../../../javascript/utilities/html_to_element"

export default class extends Controller {
  static values = { 
    autocompletePath: String,
    externalIcon: Object
  }
  static targets = [ "customUrlField" ]

  connect() {
    $("#linkPickerModal").on("shown.bs.modal", () => {
      $("#link-picker-search").trigger("focus")
    })

    $("#linkPickerMoal").on("hide.bs.modal", () => {
      $("#link-picker-search").val("")
      this.linkPickerSearch.close()
    })

    this.linkPickerSearch = new autoComplete({
      placeHolder: "Ketikkan URL atau cari judul halaman atau koleksi",
      selector: "#link-picker-search",
      wrapper: false,
      debounce: 200,
      data: {
        src: async (query) => {
          try {
            let url = `${this.autocompletePathValue}?query=${query}`
            const source = await fetch(url)
            const data = await source.json()
  
            return data.results
          } catch(error) {
            return []
          }
        },
        keys: ["title"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {
          if (data.results.length == 0) {
            const info = `
              <div
                class="list-group-item tw-border-b tw-text-gray-700 tw-p-4 tw-cursor-pointer"
                data-action="click->picker--link-component--link-component#setLink"
                data-url="${data.query}"
              >
                <div class="tw-flex tw-items-center">
                  <div class="tw-mr-4">
                    <div class="tw-h-[80px] lg:tw-h-[100px] tw-w-[80px] lg:tw-w-[100px]">
                      <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
                        ${this.externalIconValue.icon}
                      </div>
                    </div>
                  </div>
                  <div class="tw-space-y-1 lg:tw-space-y-2">
                    <div class="tw-text-gray-700 tw-font-medium">${data.query}</div>
                    <div class="">Tambahkan kustom URL</div>
                  </div>
                </div>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: { render: true },
        element: (item, data) => {
          let image_picture_element = `
            <div class="tw-h-auto tw-w-[80px] lg:tw-w-[100px]">
              <img src="${data.value.picture_url}" class="tw-w-full tw-rounded"/>
            </div>
          `
          let categoryIcon = `
            <div class="tw-h-[80px] lg:tw-h-[100px] tw-w-[80px] lg:tw-w-[100px]">
              <div class="tw-h-full tw-flex tw-items-center tw-justify-center">
                ${data.value.picture_url}
              </div>
            </div>
          `

          let image = data.value.type == "Halaman" ?  categoryIcon : image_picture_element

          item.innerHTML = `
            <div class="tw-flex tw-items-start">
              <div class="tw-mr-4">
                ${image}
              </div>
              <div class="tw-space-y-1 lg:tw-space-y-2">
                <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
                <div class="">${data.value.type}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection.value
            const linkGID = selection.gid
            const title = selection.title

            document.querySelector("#linkFieldId").value = linkGID
            document.querySelector("#linkFieldLabel").value = title

            $("#linkPickerModal").modal("hide")
          }
        }
      }
    })
  }

  setLink(event) {
    const value = event.currentTarget.dataset.url.trim()
    document.querySelector("#linkFieldId").value = value
    document.querySelector("#linkFieldLabel").value = value

    $("#linkPickerModal").modal("hide")
  }
}
