import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import htmlToElement from "../../../javascript/utilities/html_to_element"
import { xOutline, xCircleSolid } from "../../../javascript/utilities/heroicons"

export default class extends Controller {
  static targets = ["iconPreview"]
  static values = {
    iconFieldId: String,
    pickerId: String,
    pickerToggleButtonId: String,
    icons: Array
  }

  connect() {
    $(`#${this.pickerIdValue}`).on("shown.bs.modal", () => {
      $("#icons-search").trigger("focus")
    })

    $(`#${this.pickerIdValue}`).on("hide.bs.modal", () => {
      $("#icons-search").val("")
      this.iconAutocomplete.close()
    })

    this.iconAutocomplete = new autoComplete({
      selector: "#icons-search",
      wrapper: false,
      data: {
        src: this.iconsValue,
        keys: ["icon"]
      },
      resultsList: {
        destination: "#searchResult",
        position: "afterbegin",
        class: "list-group list-group-flush tw-shadow tw-rounded-0",
        noResults: true,
        maxResults: 20,
        element: (list, data) => {

          if (data.results.length == 0) {
            const info = `
              <div class="list-group-item tw-border-b-0 tw-text-gray-700 tw-p-4 tw-py-8 tw-justify-center tw-flex tw-items-center">
                ${xCircleSolid("tw-h-5 tw-w-5 tw-mr-2")}
                <span>
                  Tidak ada hasil untuk <span class="tw-font-semibold">${data.query}</span>
                </span>
              </div>
            ` 
            list.classList.remove("tw-shadow")
            list.prepend(htmlToElement(info));
          }
        }
      },
      resultItem: {
        class: "list-group-item list-group-item-action tw-p-4 tw-border-b",
        highlight: {
            render: true
        },
        element: async (item, data) => {
          const svgIcon = data.value.svg

          item.innerHTML = `
            <div class="tw-flex tw-items-center">
              <div class="tw-mr-4">
                ${svgIcon}
              </div>
              <div class="tw-space-y-1 lg:tw-space-y-2">
                <div class="tw-text-gray-700 tw-font-medium">${data.match}</div>
              </div>
            </div>
          `
        }
      },
      events: {
        input: {
          keydown: () => {
            this.iconPreviewTarget.classList.add("tw-hidden")
          },
          blur: () => {
            this.iconPreviewTarget.classList.remove("tw-hidden")
          },
          selection: (event) => {
            const selection = event.detail.selection.value
            const icon = selection.icon
            const svgIcon = selection.svg

            let iconField = document.querySelector(`#${this.iconFieldIdValue}`)
            let iconButton = document.querySelector(`#${this.pickerToggleButtonIdValue}`)
        
            iconField.value = icon
            iconButton.innerHTML = svgIcon
            this.iconAutocomplete.input.value = ""

            $(`#${this.pickerIdValue}`).modal("hide")
          }
        }
      }
    })
  }

  deleteIcon(event) {
    let iconField = document.querySelector(`#${this.iconFieldIdValue}`)
    let iconButton = document.querySelector(`#${this.pickerToggleButtonIdValue}`)

    iconField.value = ""
    iconButton.innerHTML = xOutline("tw-h-10 tw-w-10")
    $(`#${this.pickerIdValue}`).modal("hide")
  }

  selectIcon(event) {
    let iconField = document.querySelector(`#${this.iconFieldIdValue}`)
    let iconButton = document.querySelector(`#${this.pickerToggleButtonIdValue}`)

    iconField.value = event.currentTarget.dataset.icon
    iconButton.innerHTML = event.currentTarget.innerHTML
    $(`#${this.pickerIdValue}`).modal("hide")
  }
}
