
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "regenciesList", "districtsList", "coverageAreaList", "districtCode",
    "coverageAreaLabel", "flash", "districtCodeLabel"
  ]

  handleSelectProvince(event) {
    let provinceId = event.target.dataset.provinceId
    let regenciesUrl = this.data.get("regenciesUrl")
    let regenciesUrlString = `${regenciesUrl}?province_code=${provinceId}&select_action=click-%3Eaddresses%23handleSelectRegency`
    this.provinceName = event.target.dataset.provinceName
    let regenciesListElement = this.regenciesListTarget

    fetch(regenciesUrlString, {credential: "include"})
      .then(response => response.text())
      .then(html => {
        regenciesListElement.innerHTML = html
        var active = $('.wizard .nav-tabs li.active');
        active.next().removeClass('disabled');
        $(active).next().find('a[data-toggle="tab"]').trigger('click');
      })
  }

  handleSelectRegency(event) {
    let regencyCode = event.target.dataset.regencyCode
    let districtsUrl = this.data.get("districtsUrl")
    let districtsUrlString = `${districtsUrl}?regency_code=${regencyCode}&select_action=click-%3Eaddresses%23handleSelectCoverageDistrict`
    let districtsListElement = this.districtsListTarget
    this.regencyName = event.target.dataset.regencyName
    this.coverageAreaLabel = `${event.target.dataset.regencyName}, ${this.provinceName}`
   
    fetch(districtsUrlString, {credential: "include"})
      .then(response => response.text())
      .then(html => {
        districtsListElement.innerHTML = html
        var active = $('.wizard .nav-tabs li.active');
        active.next().removeClass('disabled');
        $(active).next().find('a[data-toggle="tab"]').trigger('click');
      })
  }

  handleSelectCoverageArea(event) {
    this.coverageAreaLabel = event.target.dataset.coverageAreaLabel
    let coverageAreaId = event.target.dataset.coverageAreaId
    let url = this.data.get("addressesUrl")
    let urlString = `${url}?id=${coverageAreaId}`
    let districtsListElement = this.districtsListTarget 
    $('.btn-coverage-area.active').removeClass('active');
    event.target.classList.add("active")

    fetch(urlString, {credential: 'include'})
      .then(response => response.text())
      .then(html => {
        districtsListElement.innerHTML = html
        var active = $('.wizard .nav-tabs li.active');
        active.next().removeClass('disabled');
        $(active).next().find('a[data-toggle="tab"]').trigger('click');
      })
  }

  handleSelectCoverageDistrict(event) {
    $('.btn-coverage-district.active').removeClass('active');
    event.target.classList.add("active")
    this.districtCodeTarget.value = event.target.dataset.districtCode
    this.districtCodeLabelTarget.value = `${event.target.dataset.districtName}, ${this.coverageAreaLabel}`
    var active = $('.wizard .nav-tabs li.active');
    active.next().removeClass('disabled');
    $(active).next().find('a[data-toggle="tab"]').trigger('click');
  }

  handleSubmit(e) {
    e.preventDefault()
    let form = document.getElementById("new_address")
    let districtCodeInput = this.districtCodeTarget.value
    let labelInput = document.getElementById("address_label").value
    let contentInput = document.getElementById("address_content").value
    let flashContainer = this.flashTarget

    if (districtCodeInput && labelInput && contentInput) {
      form.submit()
    } else {
      flashContainer.innerHTML = this.renderFlash()
    }
  }

  renderFlash() {
    return `
    <div class="card border-0 rounded-0 bg-warning-200">
      <div class="card-body d-flex align-items-center">
        <i class="fa fa-exclamation-triangle fa-2x text-danger text-black mr-2" aria-hidden="true"></i>
        Mohon periksa kembali. Label dan Detail alamat tidak boleh kosong 
      </div>
    </div>
    `
  }
}
