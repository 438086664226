import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"
import { Navigation, Pagination, Autoplay } from "swiper/modules"

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    this.swiper = new Swiper(`.${this.selectorValue}`, {
      modules: [Navigation, Pagination, Autoplay],
      slidesPerView: 2,
      spaceBetween: 20,
      loop: true,
      autoHeight: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        640: { slidesPerView: 2 },
        768: { slidesPerView: 4 },
        1024: { slidesPerView: 5 }
      }
    })
  }
}