export function ya() {
  console.log('ya')
}

var monthString = {
  "Jan": 0,
  "Feb": 1,
  "Mar": 2,
  "Apr": 3,
  "May": 4,
  "Jun": 5,
  "Jul": 6,
  "Aug": 7,
  "Sep": 8,
  "Oct": 9,
  "Nov": 10,
  "Dec": 11
}

function treatAsUTC(date) {
  let result = new Date(date)
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
  return result
}

export function daysBetween(startDate, endDate) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay
}

export function startTimeLessThanEndTime(start, end) {
  let startTime = dateTimeFromStringShort(start)
  let endTime = dateTimeFromStringShort(end)

  if (startTime < endTime) {
    return true
  } else {
    return false
  }
}

// Date Format : 8-Feb-2021 23:00:00
export function dateTimeFromStringShort(dateTimeString) {
  const trimmedDateTime = dateTimeString.trim()

  const dateString = trimmedDateTime.split(" ")[0]
  const timeString = trimmedDateTime.split(" ")[1]

  const dateArr = dateString.split("-")
  const timeArr = timeString.split(":")

  const dateTime = new Date(
    dateArr[2],
    monthString[dateArr[1].trim()],
    dateArr[0],
    timeArr[0],
    timeArr[1],
    timeArr[2]
  )

  return dateTime
}

function dateFromString(dateString) {
  let parts = dateString.split('-');
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

/**
 * Convert Date String from `pickadate` to new Date instance
 * created using integer value.
 *
 * @param {String}   dateString           Date string from pickadate 
 *                                        using dd-mm-yyyy format.
 * 
 * @return Date.
 */
export function dateFromStringShort(dateString) {
  let dateArr = dateString.split('-');

  return new Date(
    parseInt(dateArr[2].trim()),
    parseInt(monthString[dateArr[1].trim()]),
    parseInt(dateArr[0].trim())
  )
}

export function daysBetweenString(startString, endString) {
  const startDate = dateFromString(startString)
  const endDate = dateFromString(endString)
  return daysBetween(startDate, endDate)
}

/**
 * Adjust miminum date on `start at` input element.
 *
 * @param {HTML Input Element}   startDateInput          Input field for start date
 * @param {HTML Input Element}   endDateInput            Input field for end date
 * 
 * @return {void}.
 */
export function adjustStartLimit(startDateInput, endDateInput, offset=1) {
  var endAtInput = endDateInput.pickadate('picker');
  var startAtString = startDateInput.pickadate('picker').get();
  var startAt = dateFromStringShort(startAtString);
  var minDate = new Date(startAt);
  minDate.setDate(minDate.getDate() + offset);
  endAtInput.set('min', minDate);
}

/**
 * Adjust maximum date on `end at` input element.
 *
 * @param {HTML Input Element}   startDateInput          Input field for start date
 * @param {HTML Input Element}   endDateInput            Input field for end date
 * 
 * @return {void}.
 */
export function adjustFinishLimit(startDateInput, endDateInput, offset=1) {
  var startAtInput = startDateInput.pickadate('picker');
  var endAtString = endDateInput.pickadate('picker').get();
  var endAt = dateFromStringShort(endAtString);
  var maxDate = new Date(endAt);
  maxDate.setDate(maxDate.getDate() - offset);
  startAtInput.set('max', maxDate);
}

export function dateString(date, time) {
  let result = formatDate(date)
  return `${result} ${time}`
}

function formatDate(dateString) {
  const date = new Date(dateString)
  const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'Mei', 'Jun', 'Jul',
    'Agu', 'Sep', 'Okt',
    'Nov', 'Des'
  ]

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear().toString().substr(-2)

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}
