import { Controller } from "@hotwired/stimulus"
import Turbolinks from "turbolinks"

export default class extends Controller {
  static targets = ["month", "year"]

  search(e) {
    const month = this.monthTarget.value
    const year = this.yearTarget.value
    const url = this.data.get('url')

    Turbolinks.visit(`${url}?month=${month}&year=${year}`)
  }
}
