import { Controller } from "@hotwired/stimulus"
import * as dateUtils from "../../packs/date_utils"

export default class extends Controller {
  static targets = ["startDate", "endDate", "durationDay"]
  static values = { includeStartDateOnDuration: { type: Boolean, default: false } }

  connect() {
    const _this = this

    $(this.startDateTarget).on("change", function() {
      dateUtils.adjustStartLimit($(this), $(_this.endDateTarget), 0);

      if(_this.endDateTarget.value != "") {
        _this.writeDurationDay()
      }
    })

    $(this.endDateTarget).on("change", function() {
      dateUtils.adjustFinishLimit($(_this.startDateTarget), $(this), 0);

      if(_this.startDateTarget.value != "") {
        _this.writeDurationDay()
      }
    })
  }

  writeDurationDay() {
    const startDateString = this.startDateTarget.value
    const endDateString = this.endDateTarget.value
    const startDate = dateUtils.dateFromStringShort(startDateString)
    const endDate = dateUtils.dateFromStringShort(endDateString)

    if (startDate && endDate) {
      const period = dateUtils.daysBetween(startDate, endDate)
      this.durationDayTarget.innerHTML = `(${this.includeStartDateOnDurationValue ? (period + 1) : period} hari)`
    }
  }
}