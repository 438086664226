import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["quantity", "quantityForm", "loadingOverlay"]
  static values = { currentQuantity: Number }

  increaseQuantity(event) {
    let quantity = parseInt(this.quantityTarget.value)

    this.quantityTarget.value = quantity + 1
    this.submitForm()
  }

  reduceQuantity(event) {
    let quantity = parseInt(this.quantityTarget.value)

    this.quantityTarget.value = quantity - 1
    this.submitForm()
  }

  handleUpdateQuantity(event) {
    let quantity = parseInt(event.currentTarget.value)
    
    if (!isNaN(quantity) && quantity == this.currentQuantityValue) return

    if (isNaN(quantity)) {
      this.quantityTarget.value = 1

      if (this.currentQuantityValue != 1) {
        this.submitForm()
      }
    } else {
      this.submitForm()
    }
  }

  submitForm() {
    this.loadingOverlayTarget.classList.remove("tw-hidden")
    Rails.fire(this.quantityFormTarget, "submit")
  }
}
