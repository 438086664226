export function xOutline(cssClass) {
  return `
    <!-- Heroicon: outline/x -->
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" class="${cssClass}">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  `
}

export function xCircleSolid(cssClass) {
  return `
    <!-- Heroicon: solid/x-circle -->
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="${cssClass}">
      <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
    </svg>
  `
}

export function cubeOutline(cssClass) {
  return `
    <!-- Heroicon: outline/cube -->
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
    </svg>
  `
}

export function exclamationCircleSolid(cssClass) {
  return `
    <!-- Heroicon: solid/exclamation-circle -->
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
    </svg>
  `
}

export function exclamationCircleOutline(cssClass) {
  return `
    <!-- Heroicon: outline/exclamation-circle -->
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  `
}

export function minusSmOutline(cssClass) {
  return `
    <!-- Heroicon: outline/minus-sm -->
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M20 12H4" />
    </svg>
  `
}

export function trashOutline(cssClass) {
  return `
    <!-- Heroicon: outline/trash -->
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
    </svg>
  `
}

export function userAddOutline(cssClass) {
  return `
    <!-- Heroicon: outline/user-add -->
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
    </svg>
  `
}

export function tagOutline(cssClass) {
  return `
    <svg xmlns="http://www.w3.org/2000/svg" class="${cssClass}" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
    </svg>
  `
}
